.user-menu {
  .fas {
    font-size: 1.5rem;
  }
  .dropdown-menu {
    background: #ffffff;
    border-radius: 3px;
    padding: .5rem 0;
    border: 1px solid #dfdfdf;
    box-shadow: 4px 4px 10px 0 rgba(0,0,0,.1);
    transition: opacity .1s ease;
    .dropdown-item-header {
      padding: 0.3em 1em;
      border-bottom: 1px solid #dfdfdf;
    }
    .dropdown-item {
      display: block;
      width: 100%;
      padding: .5em 1.2em;
      border: 0;
      cursor: pointer;
  
      &:focus, &:hover {
        background-color: #fce2ef;
      }
    }
  }
  .link {
    text-decoration: none;
    color: #000000;
  }
}