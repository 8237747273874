.onoffswitch {
  $switchW: 6.5rem;
  $switchButtonSize: 1rem;
  position: relative;
  width: $switchW;
  user-select: none;
  height: 2rem;
  overflow: hidden;

  .onoffswitch-checkbox {
    display: none;
  }
  
  .onoffswitch-label {
    display: block;
    overflow: hidden;
    height: 100%;
    width: 100%;
    cursor: pointer;
    border: 1px solid #999999;
    border-radius: 1rem;
  }
  
  .onoffswitch-inner {
    display: flex;
    width: 200%;
    height: 100%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    .label-left,
    .label-right {
      display: inline-flex;
      align-items: center;
      width: 50%;
      height: 100%;
      padding: 0;
      font-size: .875rem;
      color: white;
      font-weight: bold;
    }
    .label-left {
      background-color: #34A7C1;
      color: #FFFFFF;
      justify-content: flex-start;
      padding-left: .5rem;
    }
    .label-right {
      background-color: #EEEEEE;
      color: #999999;
      justify-content: flex-end;
      padding-right: .5rem;
    }
  }
  
  .onoffswitch-switch {
    display: block;
    width: $switchButtonSize;
    margin: .5rem 0 ;
    background: #FFFFFF;
    position: absolute;
    top: 0;
    bottom: 0;
    right: $switchW - $switchButtonSize - .5rem;
    border: 2px solid #999999;
    border-radius: 1rem;
    transition: all 0.3s ease-in 0s;
  }
  
  .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
  }
  
  .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
    right: .5rem;
  }
}
// <div className="onoffswitch">
//   <input type="checkbox" name="onoffswitch"
//     className="onoffswitch-checkbox" id="priceSwitch"
//     checked={paymentTerm}
//     onChange={this.changePaymentTerm} />
//   <label className="onoffswitch-label" htmlFor="priceSwitch">
//     <span className="onoffswitch-inner">
//       <span className="label-left">Annually</span>
//       <span className="label-right">Monthly</span>
//     </span>
//     <span className="onoffswitch-switch"></span>
//   </label>
// </div>