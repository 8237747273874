.manage-discounts {
  width: 80%;
  margin: 0 auto;
  .manage-header {
    margin: 1rem 0;
    text-align: right;
  }
  table th,
  table td {
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 20%;
    }
    &:nth-child(5) {
      width: 15%;
    }
    &:nth-child(6) {
      width: 15%;
    }
  }

  .operation-item {
    color: rgb(173, 173, 190);
    margin-right: 0.5rem;
  }
}
.discount-modal-content {
  .discount-form {
    width: 30rem;
  }

  textarea {
    height: 5rem;
  }
  .select {
    display: block;
    width: 100%;
  }
}
