.sign-container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.sign-header {
  margin: 1rem 0;
}
.sign-content {
  width: 18rem;
}
.signIn-btn {
  margin-top: 1rem;
  display: block;
  width: 100%;
}
.sign-container .form-control {
  display: block;
  width: 100%;
}