.Orders {
  width: 80%;
  margin: 0 auto;
  table th,
  table td {
    &:nth-child(1) {
      width: 20%;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 15%;
    }
    &:nth-child(4) {
      width: 15%;
    }
    &:nth-child(5) {
      width: 10%;
    }
    &:nth-child(6) {
      width: 10%;
    }
    &:nth-child(7) {
      width: 15%;
    }
  }

  .organization-name {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
      color: blue;
    }
  }

}
.order-voucher-modal {
  width: 35rem;
  .voucher-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .875rem;
    margin-bottom: .625rem;
    .voucher-item-label {
      margin-right: .625rem;
      width: 10rem;
    }
    .voucher-item-value {
      display: inline-flex;
      align-items: center;
    }
  }
  .voucher-image {
    flex: 1;
    height: 20rem;
    background-size: cover;
  }
}