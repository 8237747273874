table {
  width: 100%;
  border-collapse:collapse;
  font-size: 14px;
  input{
    border:none;
    background-color: transparent;
  }
  thead {
    width: 48px;
    text-align: left;
    background: #ECF6FC;
    color: #858585;

    tr {
      height: 48px;
      th {
        font-weight: initial;
      }
    }
    &.small tr {
      height: 30px;
    }
  }

  tbody {
    color: #545454;

    tr {
      height: 60px;
      &:nth-child(even) {
        background: #F0FAFF;
        border: 1px solid #DDF2FF;
      }
    }
    &.small tr {
      height: 30px;
    }
  }
  td, th {
    padding-left: 5px;
    padding-right: 5px;
    &.first-column{
      text-indent: 20px;
    }
    &.center {
      text-align: center;
    }
  }
}