.manage-users {
  width: 80%;
  margin: 0 auto;
  .manage-header {
    margin: 1rem 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    input {
      margin-left: 1rem;
      margin-right: 1rem;
    }
    .user-role {
      display: inline-flex;
      width: 10rem;
      margin-right: 1rem;
      .Select-value {
        display: inline-flex;
        justify-content: flex-start;
      }
    }
  }
  .operation-item {
    color: rgb(173, 173, 190);
    &:first-child {
      margin-right: 0.5rem;
    }
  }
  table th,
  table td {
    &:nth-child(1) {
      width: 35%;
    }
    &:nth-child(2) {
      width: 35%;
    }
    &:nth-child(3) {
      width: 30%;
    }
  }
}