@import './table.scss';
@import './modal.scss';
@import './form.scss';
@import './switch.scss';
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: visible;
  font-size: 16px;
  font-family: Segoe UI, Helvetica Neue, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Hiragino Kaku Gothic Pro, Meiryo, Malgun Gothic, sans-serif;
}

* {
  box-sizing: border-box;
}

#root {
  height: 100%;
}
.container {
  height: 100%;
}

.text-center {
  text-align: center;
}

button,
input,
a {
  outline: none;
}

button {
  cursor: pointer;
}

.btn {
  height: 2rem;
  font-size: .875rem;
  font-weight: normal;
  line-height: 1rem;
  text-align: center;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid transparent;
  user-select: none;
}

.btn-primary {
  color: #ffffff;
  background: #337ab7;
  border-color: #337ab7;
}

.btn-primary:hover {
  background: #62B2DE;
  border-color: #62B2DE;
}

.error-message {
  color: red;
  line-height: 2rem;
}

.form-control {
  height: 2.125rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.25;
  color: #292b2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .2rem;
}

h,
h1 {
  margin: 0;
}

.text-overflow {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

textarea {
  resize: none;
  display: block;
  width: 100%;
  border: 1px solid #D6D6D6;
  border-radius: 6px;
  font-size: 14px;
  outline: none;
  padding-top: 5px;
  padding-left: 50px;
}

i {
  cursor: pointer;
}

.stripe-card {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .stripe-card-brand {
    transform: skewX(-15deg);
    background: #395273;
    color: #ffffff;
    padding: .3rem .5rem;
    border-radius: 3px;
  }
  .stripe-card-brand, .stripe-card-number, .stripe-card-exp {
    margin-left: 1rem; 
  }
}

.display-block {
  display: block;
}
.display-none {
  display: none;
}
