.user-profile {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  .header {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .stripe-cards {
    border: 1px solid #dfdfdf;
    .stripe-card {
      margin: 1rem 0;
      .operation-item {
        margin-left: 1rem;
      }
    }
  }
}