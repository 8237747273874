.manage-products {
  width: 80%;
  margin: 0 auto;
  .manage-header {
    margin: 1rem 0;
    text-align: right;
  }
  table th,
  table td {
    &.text-overflow {
      max-width: 1px;
    }
    &:nth-child(1) {
      width: 15%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 10%;
    }
    &:nth-child(4) {
      width: 15%;
    }
    &:nth-child(5) {
      width: 20%;
    }
    &:nth-child(6) {
      width: 10%;
    }
    &:nth-child(7) {
      width: 10%;
    }
  }

  .operation-item {
    color: rgb(173, 173, 190);
    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
.product-modal-content {
  .product-form {
    width: 30rem;
  }

  textarea {
    height: 5rem;
  }
  .select {
    display: block;
    width: 100%;
  }
}
