.custom-dropdown-wrapper {
  position: relative;
  overflow: visible;
  .custom-dropdown-trigger {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.custom-dropdown-list {
  position: absolute;
  width: auto;
  height: auto;
  opacity: 0;
  pointerEvents: 'none';
  z-index: 9999;
}