form {
  .input-group {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    label {
      flex: 0 0 auto;
      width: 10rem;
    }
    .validate-indicator {
      flex: 0 0 auto;
      width: 1rem;
      padding-right: .5rem;
      color: red;
    }
    .form-control {
      flex: 1 1 auto;
    }
  }
}