.modal-container {
  position: absolute;
  padding: 1rem;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem;
    span {
      flex: 1 1 auto;
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
    }
    i {
      flex: 0 0 auto;
      width: 1rem;
      cursor: pointer;
    }
  }
  .modal-content {
    font-size: 1.2rem;
  }
  .modal-footer {
    margin-top: 1rem;
    height: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 1rem;
    }
  }
  .confirm-modal-content {
    width: 15rem;
    height: 10rem;
  }
}