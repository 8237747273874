.app-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.app-content {
  flex: 1 1 auto;
}

.sign-in-status {
  display: inline-flex;
  align-items: center;
}
.user-email {
  margin-right: .5rem;
}
.sign-out, .sign-in {
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.app-header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 4rem;
  background: rgb(118, 180, 190);
}
.header-left, .header-right {
  display: flex;
  align-items: center;
  height: 100%;
}
.nav-tab {
  display: inline-block;
  text-decoration: none;
  margin-right: 1rem;
  height: 3rem;
  padding: 0.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  border-radius: 1rem;
}
.nav-tab:hover {
  background: #fce2ef;
}
.nav-tab.nav-active-tab {
  background-color: rgb(202, 218, 255)
}
.sign-in-status {
  display: inline-flex;
  align-items: center;
}
.user-email {
  margin-right: .5rem;
}
.sign-out, .sign-in {
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}
.sign-out:hover, .sign-in:hover {
  color: #ffffff;
}