.organizations {
    width: 80%;
    margin: 0 auto;

    .organization-name {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
            color: blue;
          }
    }
}

.organization-detail-modal {
    width: 80vw;
    height: 80vh;

    display: flex;
    flex-direction: column;

    .organization-thumbnail {
        flex: 0 0 auto;
        .header {
            display: flex;
            justify-content: space-between;
        }
        .organization-usage-table {
            margin: 20px 0;
        }
    }

    .modal-content {
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;

        .users-table {
            margin: 20px 0;
        }
    }
}